import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ResultsFamily = _resolveComponent("ResultsFamily")!
  const _component_ResultsFamilyNextStep = _resolveComponent("ResultsFamilyNextStep")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_ResultsFamily),
    _createVNode(_component_ResultsFamilyNextStep),
    _createVNode(_component_Footer)
  ], 64))
}